import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AxiosInstance from "../../src/Common/Api/ApiHelper";
import { toast } from "react-toastify";
import { apiList } from "../Common/Api/ApiList";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const CustomModal = ({
  open,
  handleClose,
  handleAddFaq,
  mode,
  fetchUserData,
  faqData,
}) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const validateFields = () => {
    let isValid = true;
    if (!question)
    {
      setQuestionError("Question is required");
      isValid = false;
    } else
    {
      setQuestionError("");
    }
    if (!answer)
    {
      setAnswerError("Answer is required");
      isValid = false;
    } else
    {
      setAnswerError("");
    }
    return isValid;
  };

  const handleAdd = async () => {
    if (!validateFields()) return;

    setIsButtonDisabled(true);

    try
    {
      let response;
      if (mode === "add")
      {
        response = await AxiosInstance.post(apiList.AddFAQs, {
          question,
          answer,
          type: 1,
        });
      } else
      {
        response = await AxiosInstance.post(apiList.AddFAQs, {
          id: faqData.id,
          question,
          answer,
          type: 2,
        });
      }
      if (response?.data?.s === 1)
      {
        toast.success(
          mode === "add"
            ? "FAQ Added Successfully."
            : "FAQ Updated Successfully."
        );
        fetchUserData();
        setQuestion("");
        setAnswer("");
        handleClose();
      } else
      {
        toast.error(
          mode === "add" ? "Failed to add FAQ." : "Failed to update FAQ."
        );
      }
    } catch (error)
    {
      console.error("Error adding/updating FAQ:", error);
    } finally
    {
      setIsButtonDisabled(false);
    }
  };

  const handleQuestionChange = (e) => {
    setQuestion(e?.target?.value);
    if (questionError)
    {
      setQuestionError("");
    }
  };

  const handleAnswerChange = (e) => {
    setAnswer(e?.target?.value);
    if (answerError)
    {
      setAnswerError("");
    }
  };

  useEffect(() => {
    if (faqData && mode === "edit")
    {
      setQuestion(faqData?.question);
      setAnswer(faqData?.answer);
    } else
    {
      setQuestion("");
      setAnswer("");
    }
  }, [faqData, mode]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
          mb={2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              {mode === "add" ? "Add FAQ" : "Edit FAQ"}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <input
            type="text"
            value={question}
            onChange={handleQuestionChange}
            placeholder="Question"
            style={{
              width: "100%",
              height: "45px",
              borderRadius: "8px",
              border: "none",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              paddingLeft: "10px",
              fontSize: "16px",
              outline: "none",
            }}
          />
          {questionError && (
            <Typography color="error" variant="caption">
              {questionError}
            </Typography>
          )}
          <input
            type="text"
            value={answer}
            onChange={handleAnswerChange}
            placeholder="Answer"
            style={{
              width: "100%",
              height: "45px",
              borderRadius: "8px",
              border: "none",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              paddingLeft: "10px",
              fontSize: "16px",
              outline: "none",
            }}
          />
          {answerError && (
            <Typography color="error" variant="caption">
              {answerError}
            </Typography>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          disabled={isButtonDisabled}
          sx={{ mt: 3, width: "100%", color: "white" }}
        >
          {mode === "add" ? "Add FAQ" : "Update FAQ"}
        </Button>
      </Box>
    </Modal>
  );
};

export default CustomModal;
