import {
  Box,
  Button,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import { DataGrid } from "@mui/x-data-grid";

export default function UserEmergencyDetailsTable(props) {
  const theme = useTheme();

  const {
    sessiondetails,
    isLoading,
    totalData,
    rowsPerPage,
    loading,
  } = props;

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "#",
      width: 80,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "number",
      headerName: "Contact No.",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <Box
      sx={{
        height: "calc(100vh - 450px)",
      }}
    >
      <DataGrid
        pagination
        loading={loading}
        rowCount={totalData}
        rows={sessiondetails ?? []}
        disableColumnMenu={true}
        disableAutosize={true}
        paginationMode="server"
        hideFooter
        disableColumnFilter={true}
        getRowId={(e) => e?.id}
        slots={{ noRowsOverlay: NoDataImg }}
        columns={columns}
        sx={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          ".MuiDataGrid-cell": { textAlign: "center" },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
            color: "white",
          },
          "& .super-app-theme--header": {
            background: theme.palette.primary.Blend,
          },
        }}
        pageSize={rowsPerPage}
      />
      {/* <TablePagination
                component="div"
                count={totalData}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            /> */}
    </Box>
  );
}
