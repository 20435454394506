import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { Box, Typography, useTheme } from "@mui/material";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import moment from "moment";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";
import UserSessionDetailsTable from "./UserSessionDetailsTable";
import NoDataImg from "../../Common/NoData";
import ConfirmationModal from "../../Component/ConfirmationModal";
import ErrorToaster from "../../Component/ErrorToaster";
import { AppDomain } from "../../Common/Constant";
import UserEmergencyDetailsTable from "./UserEmergencyDetailsTab";

const PAGE_SIZE_OPTIONS = [30];
const UserDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState({});
  const { id } = useParams();
  const [actionLoading, setActionLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [loading, setloading] = useState(false);
  const theme = useTheme();

  const fetchUserData = async (page = 0, pageSize = PAGE_SIZE_OPTIONS[0]) => {
    try
    {
      setloading(true);
      let count = page * pageSize;
      const response = await AxiosInstance.get(
        `${apiList.UserDetailsById}?id=${id}&count=${count}`
      );
      if (response?.data?.r)
      {
        setData(response?.data?.r);
        setTotalData(response?.data?.r?.total_count);
        setIsLoading(false);
      } else
      {
        setIsLoading(false);
        setTotalData(0);
      }
      setloading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setloading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const navigate = useNavigate();

  const handleDelete = async (id, status) => {
    try
    {
      setActionLoading(true);
      const res = await AxiosInstance.post(`${apiList.DeleteUser}`, {
        id: id,
        status: status,
      });
      if (res.data.s === 1)
      {
        toast.success("User deleted successfully");
        navigate(-1);
      } else
      {
        toast.error(res.data.m);
      }
      setActionLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setActionLoading(false);
    }
  };
  const handleBlock = async (id, status) => {
    try
    {
      setActionLoading(true);
      const res = await AxiosInstance.post(`${apiList.DeleteUser}`, {
        id: id,
        status: status,
      });
      if (res.data.s === 1)
      {
        toast.success(
          `User ${status === -1 ? "restricted" : "unrestricted"} successfully.`
        );
        fetchUserData();
        // navigate(-1);
      } else
      {
        toast.error(res.data.m);
      }
      setActionLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setActionLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          // overflow: "hidden",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
          onClick={handleBacktoUserList}
        >
          <img
            src={backbutton}
            alt=""
            style={{
              height: "100%",
            }}
          />
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "22px",
              paddingLeft: "40px",
            }}
          >
            User Detail
          </Typography> 
        </Box> */}
        <Box
          sx={{
            height: "calc(100vh - 77px)",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    m: 3,
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: { xs: "center", md: "start" },
                  }}
                >
                  <Box>
                    {data?.profile_img ? (
                      <a
                        href={
                          data.profile_img.includes("instatrainme")
                            ? data.profile_img
                            : `${AppDomain}/${data.profile_img}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={
                            data.profile_img.includes("instatrainme")
                              ? data.profile_img
                              : `${AppDomain}/${data.profile_img}`
                          }
                          alt=""
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "50%",
                            border: "1px solid #E0E0E0",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                    ) : (
                      <img
                        src={dummyimage}
                        alt=""
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "1px solid #E0E0E0",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      pl: 3,
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      justifyContent: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: "500",
                      }}
                    >
                      {data?.first_name} {data?.last_name}{" "}
                      <span style={{ color: "grey", fontSize: "18px" }}>
                        {data?.gender === 1
                          ? "(Male)"
                          : data?.gender === 2
                            ? "(Female)"
                            : data?.gender === 3
                              ? "(Other)"
                              : ""}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      <a
                        style={{
                          color: "#000",
                          textDecoration: "none",
                        }}
                        href={`mailto:${data.email}`}
                      >
                        {" "}
                        {data.email}
                      </a>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      DOB :{" "}
                      {data?.dob === null ? (
                        <span style={{ color: "grey", opacity: "0.75" }}>
                          NA
                        </span>
                      ) : (
                            moment(data?.dob).format("L")
                      )}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        Height :{" "}
                        {data?.height === null ? (
                          <span style={{ color: "grey", opacity: "0.75" }}>
                            NA
                          </span>
                        ) : (
                          `${data?.height}cm.`
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        Weight :{" "}
                        {data?.weight === null ? (
                          <span style={{ color: "grey", opacity: "0.75" }}>
                            NA
                          </span>
                        ) : (
                          `${data?.weight}kg.`
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "#5B5B5B",
                        wordBreak: "break-word",
                      }}
                    >
                      {data?.bio === null ? "" : data?.bio}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", md: "column" },
                    gap: { xs: "10px", md: "10px" },
                    pb: { xs: 3, md: 0 },
                  }}
                >
                  <ConfirmationModal
                    handleClick={handleBlock}
                    ModalTitle={data?.status === 1 ? "Restrict" : "Unrestrict"}
                    submitBtnTitle={"Confirm"}
                    bgColor={"#F2F2F2"}
                    color={data?.status === 1 ? "#000" : "red"}
                    id={id}
                    loading={actionLoading}
                    status={data?.status === 1 ? -1 : 1}
                  />

                  <ConfirmationModal
                    handleClick={handleDelete}
                    ModalTitle={
                      data?.status === 0 || data.is_admin_approval === -2
                        ? "Deleted"
                        : "Delete"
                    }
                    submitBtnTitle={"Confirm"}
                    bgColor={"#FACCCC"}
                    color={"red"}
                    id={id}
                    loading={actionLoading}
                    status={-2}
                    disable={
                      data?.status === 0 || data.is_admin_approval === -2
                        ? true
                        : false
                    }
                  />
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label="Personal Information"
                      {...a11yProps(0)}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    />
                    <Tab
                      label="Session Details"
                      {...a11yProps(1)}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    />
                    <Tab
                        label="Emergency Contact Details"
                      {...a11yProps(2)}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Box
                    sx={{
                      width: "100%",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                      maxHeight: { md: "calc(100vh - 440px)" },
                      overflowY: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        background: theme.palette.primary.Blend,
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        height: "50px",
                        position: "sticky",
                        top: "0",
                      }}
                    >
                      <Typography
                        p={1.5}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "white",
                        }}
                      >
                        Fitness Goals Category
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        p: 1.5,
                        justifyContent:
                          data?.personal_Info?.goalsCategory.length > 0
                            ? "start"
                            : "center",
                        width: { xs: "100%", md: "100%" },
                        height: { md: "calc(100% - 50px)" },
                      }}
                    >
                      {data?.personal_Info?.goalsCategory.length > 0 ? (
                        data?.personal_Info?.goalsCategory.map((x) => {
                          return (
                            <Typography
                              sx={{
                                background: "#CEE5FF",
                                color: "black",
                                borderRadius: "8px",
                                px: 2,
                                py: 1,
                              }}
                            >
                              {x.sub_cat_name}
                            </Typography>
                          );
                        })
                      ) : (
                        <NoDataImg />
                      )}
                    </Box>
                    <Box sx={{ bgcolor: "#EFEFEF" }}>
                      <Typography
                        p={1}
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        Health Info
                      </Typography>
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.question_1}?
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          pb: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.answer_1 === null ? (
                          <span style={{ color: "grey", opacity: "0.75" }}>
                            NA
                          </span>
                        ) : (
                          data?.personal_Info?.healthInfo?.answer_1
                        )}
                      </Typography>
                      <hr />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          pt: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.question_2}?
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          pb: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.answer_2 === null ? (
                          <span style={{ color: "grey", opacity: "0.75" }}>
                            NA
                          </span>
                        ) : (
                          data?.personal_Info?.healthInfo?.answer_2
                        )}
                      </Typography>
                      <hr />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          pt: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.question_3}?
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "500",
                          pb: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {data?.personal_Info?.healthInfo?.answer_3 === null ? (
                          <span style={{ color: "grey", opacity: "0.75" }}>
                            NA
                          </span>
                        ) : (
                          data?.personal_Info?.healthInfo?.answer_3
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <UserSessionDetailsTable
                    sessiondetails={data && data?.session_details}
                    totalData={totalData}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    handleRowsPerPageChange={handleRowsPerPageChange}
                    PAGE_SIZE_OPTIONS={PAGE_SIZE_OPTIONS}
                    isLoading={isLoading}
                    loading={loading}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <UserEmergencyDetailsTable
                    sessiondetails={data && data?.emergency_contact_list}
                    totalData={totalData}
                    rowsPerPage={rowsPerPage}
                    isLoading={isLoading}
                    loading={loading}
                  />
                </CustomTabPanel>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default UserDetailsPage;
