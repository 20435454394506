import { Box, Typography } from "@mui/material";
import React from "react";
import NoDataImg from "../../../Common/NoData";
import { AppDomain } from "../../../Common/Constant";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export default function Certification({ data }) {
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        // minHeight: '350px',
        overflow: { md: "scroll" },

        borderRadius: "8px",
      }}
      className='HideScrollBar'

    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          height: "50px",
          position: "sticky",
          top: "0",
          zIndex: 1,
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        Certifications
      </Typography>

      <Box sx={{ height: "calc(100% - 50px)", overflow: "scroll" }}>
        {data?.certications?.certificate.length > 0 ? (
          <Box sx={{ p: 2 }}>
            {data?.certications?.certificate?.map((certi, i) => {
              const ext = certi.certificate_file.split(".");
              return (
                <Box
                  sx={{
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  key={i}
                >
                  <>
                    <Typography> {certi?.certificate_name}</Typography>

                    <Box
                      sx={{
                        p: 1,
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <a
                        href={
                          certi?.certificate_file?.includes("instatrainme")
                            ? certi?.certificate_file
                            : `${AppDomain}${certi?.certificate_file}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "black",
                          textDecoration: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.2rem",
                          position: "relative",
                        }}
                      >
                        {/* <img src={pdficon} alt="" style={{ width: "20px" }} />{" "} */}
                        <InsertDriveFileIcon style={{ color: "#ea0606" }} />
                        <span
                          style={{
                            fontSize: "0.4rem",
                            color: "white",
                            position: "absolute",
                            paddingTop: "11px",
                            left: 5,
                            letterSpacing: "1px",
                          }}
                        >
                          {ext[ext.length - 1]?.toUpperCase()}
                        </span>
                        <span
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >{`Certificate ${i > 0 ? i : ""}`}</span>
                      </a>
                    </Box>
                  </>

                  <hr
                    style={{
                      background: "#CECECE",
                      opacity: "0.3",
                      margin: "1rem 0 .5rem 0",
                      display:
                        data?.certications.certificate.length - 1 !== i
                          ? "initial"
                          : "none",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
        <Typography sx={{ p: 1, background: "#EFEFEF" }}>
          Vaccine Certificate
        </Typography>
        {data?.certications?.vaccine_certificate.length > 0 ? (
          <Box sx={{ p: 2 }}>
            {data?.certications?.vaccine_certificate?.map((certi, i) => {
              const ext = certi.vaccine_certificate_file.split(".");
              return (
                <Box
                  sx={{
                    borderRadius: "8px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  key={i}
                >
                  <>
                    {/* <Typography> {certi.certificate_name}</Typography> */}

                    <Box
                      sx={{
                        p: 1,
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        width: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <a
                        href={
                          certi.vaccine_certificate_file.includes(
                            "instatrainme"
                          )
                            ? certi.vaccine_certificate_file
                            : `${AppDomain}${certi.vaccine_certificate_file}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "black",
                          textDecoration: "none",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.2rem",
                          position: "relative",
                        }}
                      >
                        {/* <img src={pdficon} alt="" style={{ width: "20px" }} />{" "} */}
                        <InsertDriveFileIcon style={{ color: "#ea0606" }} />
                        <span
                          style={{
                            fontSize: "0.4rem",
                            color: "white",
                            position: "absolute",
                            paddingTop: "11px",
                            left: 5,
                            letterSpacing: "1px",
                          }}
                        >
                          {ext.pop().toUpperCase()}
                        </span>
                        <span
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >{`Certificate ${i > 0 ? i : ""}`}</span>
                      </a>
                    </Box>
                  </>

                  <hr
                    style={{
                      background: "#CECECE",
                      opacity: "0.3",
                      margin: "1rem 0 .5rem 0",
                      display:
                        data?.certications.vaccine_certificate.length - 1 !== i
                          ? "block"
                          : "none",
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
      </Box>
    </Box>
  );
}
